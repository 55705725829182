import { useNavigate } from "react-router-dom";

//MUI
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";

//Components
import dojo from "../../images/dojo1.jpg";

export default function HomePageSmall() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid container spacing={0}>
      <Grid item xs={100}>
        <Box
          component="img"
          src={dojo}
          sx={{ width: "100%", height: "100vh", objectFit: "cover" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: "bolder",
              fontSize: "7vmin",
              color: theme.palette.primary.light,
            }}
          >
            Entrainez-vous avec nous!
          </Typography>

          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate("/classes");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            Voir nos cours
          </Button>
        </Box>
      </Grid>

      <Grid
        item
        xs={100}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box p={2}>
            <Typography variant="h4" sx={{ fontSize: "45px" }} p={2}>
              Qu'est-ce que le Jiu-Jitsu Brésilien?
            </Typography>
            <Typography sx={{ fontSize: "20px" }} p={2}>
              Le Jiu-Jitsu brésilien (JJB en français et BJJ en anglais) est un
              art martial brésilien dérivé du Jiu-Jitsu japonais traditionnel et
              du judo, qui se pratique principalement au sol et dont le but est
              de soumettre l’adversaire par étranglement ou clé d’articulation.
              Les frappes (coups de pied, poing, coude, tête et genou)
              volontaires sont interdites dans la pratique officielle.
              <br />
              <br />
              Le Jiu-Jitsu brésilien est un art martial moderne concentré sur la
              technique, le timing et l’effet de levier plutôt que dans la force
              brute, permettant ainsi de dominer des adversaires plus imposants.
              Comme le judo, on y retrouve des projections au sol, des
              immobilisations et des clés aux articulations.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
