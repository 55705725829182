//MUI
import { Box, Grid, Typography } from "@mui/material";

//Components
import stephane from "../../images/stephane.jpg";

export default function CoachesPageSmall() {
  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={100}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box
              component="img"
              src="https://bjj-world.com/wp-content/uploads/2020/12/Screenshot_1293.jpg"
              sx={{ width: "100%" }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding={2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "28px", fontWeight: "bold" }}
              >
                Stéphane Séguin
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                L’instructeur Stéphane Séguin est ceinture brune de jiu-jitsu
                brésilien et est affilié à HOMA bjj. Il a été introduit à cet
                art ensemble par Julio «Foca» Fernandez de l’équipe Carlson
                Gracie qui a participé à plusieurs tournois, dont le prestigieux
                Championnats panaméricains.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                « Mon parcours… je pratique les arts martiaux depuis plus de 25
                ans. J’ai une ceinture noire en ninjutsu; un sport qui contient
                du Ne-Waza(combat au sol), et j’ai aussi un bagage de boxe, de
                judo, de taiho-jutsu, ainsi qu’une ceinture brune en jiu jitsu
                brésilien. J’ai remporté la médaille d’or au Vermont Open BJJ
                1998 et la médaille d’or au Montgomery Open BJJ 1999 ».
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={100}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box
              component="img"
              src="https://img.olympicchannel.com/images/image/private/t_s_pog_staticContent_hero_xl_2x/f_auto/primary/w1eo4el8wr6prjicq5n4"
              width="100%"
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              padding={2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "28px", fontWeight: "bold" }}
              >
                Nicolas Borduas
              </Typography>
              <br />
              <Typography sx={{ fontSize: "21px" }}>
                L’assistant instructeur Nicolas est présentement ceinture mauve
                en jiu jitsu brésilien. Il a plus de 10 ans d'expérience en
                lutte ayant pratiqué avec les Patriotes et par la suite avec
                l'équipe Nationale de lutte olympique ce qui fait de lui un
                élément clé de notre aprentissage!
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
